import instance from '../index';

export const ReplicateApi = {
  removeBackground({ imageId, image }) {
    const formData = new FormData();

    // Append image with filename and content-type
    formData.append('image', image, {
      filename: 'image.webp',
      contentType: 'image/webp'
    });
    return instance.post(`/images/${imageId}/remove-background`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  removeObjects({ imageId, image, mask }) {
    const formData = new FormData();

    // Append image with filename and content-type
    formData.append('image', image, {
      filename: 'image.webp',
      contentType: 'image/webp'
    });

    // Append mask with filename and content-type
    formData.append('mask', mask, {
      filename: 'mask.png',
      contentType: 'image/png'
    });

    return instance.post(`/images/${imageId}/remove-objects`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  generateObjects({ imageId, mask, image, prompt }) {
    const formData = new FormData();

    // Append image with filename and content-type
    formData.append('image', image, {
      filename: 'image.jpg',
      contentType: 'image/jpeg'
    });

    // Append mask with filename and content-type
    formData.append('mask', mask, {
      filename: 'mask.png',
      contentType: 'image/png'
    });

    return instance.post(`/images/${imageId}/generate-objects?prompt=${encodeURIComponent(prompt)}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  getPredictionStatus(imageId, predictionId) {
    return instance.post(`/images/${imageId}/prediction-status`, { predictionId });
  },

  outpaint({ imageId, image, outpaint, prompt }) {
    const formData = new FormData();

    // Append image with filename and content-type
    formData.append('image', image, {
      filename: 'image.webp',
      contentType: 'image/webp'
    });

    return instance.post(`/images/${imageId}/outpaint?outpaint=${encodeURIComponent(outpaint)}&prompt=${encodeURIComponent(prompt)}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};

export default ReplicateApi;
